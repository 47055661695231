<template>
  <a-card
    :header="$t('failures.state.header', { number: doc.number || ''})"
    :editable="editable"
  >
    <a-value
      field="odometer"
      :value-formatter="(odometer) => `${odometer} km`"
    />

    <a-value
      field="type"
      :class="[{ 'has-text-danger': doc.type == 'guarantee' }] "
    />

    <a-value
      v-if="doc.type === 'guarantee'"
      field="recognized"
    />

    <a-value
      :class="rowClass"
      field="state"
    />

    <a-value
      field="solution"
    />

    <a-value
      field="location"
    />

    <a-value
      field="contract_number"
    />

    <a-value
      field="order_number"
    />

    <a-value
      field="name"
    />

    <a-value
      field="description"
    />

    <a-value
      field="resolution"
    />

    <template
      #form
    >
      <a-select
        field="state"
        :options="stateOptions"
        @change="changeState"
      />

      <a-select
        field="location"
        null-option
      />

      <a-input
        field="name"
        :required="true"
      />

      <a-input
        field="description"
        type="textarea"
      />

      <a-input
        field="resolution"
        type="textarea"
      />

      <a-input
        field="odometer"
        type="number"
      />

      <a-suggest-input
        suggestion-id="failures.contract_number"
        field="contract_number"
        :required="true"
      />

      <a-input
        field="order_number"
        update-on-blur
      />

      <a-select
        field="type"
      />

      <a-select
        v-if="doc.type === 'guarantee'"
        field="recognized"
        null-option
      />
    </template>
  </a-card>
</template>

<script>
import ResolutionForm from './ResolutionForm'
import { getFailureNumber } from '@/callables'

export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    stateOptions() {
      let allOptions = _.clone(this.$store.getters["failures/fields"].state.options)
      const unrepaired = this.$store.getters["failureParts/hasUnrepairedCurrentFailureParts"]
      if(unrepaired) {
        _.pull(allOptions, 'completed')
        console.log('removedOptions', allOptions)
      }
      return allOptions
    },
    rowClass () {
      return this.$store.getters[`${this.module}/rowClass`](this.doc)
    }
  },
  created() {
    this.setNumber()
  },
  methods: {
    async changeState (state) {
      const self = this

      if (['serviced', 'completed'].includes(state) && !this.doc.progress[state].at) {
        this.$store.dispatch('currentDoc/set', {
          field: `progress.${state}.at`,
          value: new Date()
        })

        this.$store.dispatch('currentDoc/set', {
          field: `progress.${state}.by`,
          value: _.pick(self.user, ['id', 'name'])
        })
      }

      if (state === 'completed' && !this.doc.resolution) {
        this.$buefy.modal.open({
          parent: this,
          component: ResolutionForm,
          hasModalCard: true,
          width: 400,
          height: 400
        })
      }
    },
    async setNumber() {
      const self = this

      if (!self.doc.number) {
        self.$buefy.toast.open({
          message: `Getting new failure number`,
          duration: 0,
          position: 'is-bottom',
          type: 'is-warning'
        })
        try {
          let numbers = await getFailureNumber()
          numbers.id = self.doc.id
          await self.$store.dispatch('failures/set', numbers)
          self.$buefy.toast.open({
            message: `Successfully assigned ${numbers.number} to failure`,
            position: 'is-bottom',
            type: 'is-success'
          })
        } catch(message) {
          self.$buefy.toast.open({
            duration: 0,
            message: message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      }
    }
  }

}
</script>
